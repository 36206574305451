import { getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import Client, { getHeaderAndText } from "../../components/client/Client";
import { getNavigation } from "../../components/clients/Clients";
import i18nContext from "../../components/i18n/i18n.context";
import Layout from "../../components/layout/Layout";
import Page from "../../components/page/Page";
import SEO from "../../components/seo/Seo";
import i18n from "../../i18n/ods.i18n.js";
import { useQuery } from "../../static-queries/ods";
import { getHTMLContent } from "../../utils";

const locale = "fr";

function ClientPage({ location }) {
  const strings = i18n[locale];
  const { header, text, clientCover } = getHeaderAndText({
    strings,
    staticQuery: useQuery(),
  });
  return (
    <i18nContext.Provider value={{ locale }}>
      <Layout>
        <SEO
          description={getHTMLContent(strings.blocks[1].description)}
          image={getImage(clientCover).src}
          keywords={["Jawg", "JawgMaps", "Témoignage", "OpenDataSoft"]}
          location={location}
          metaDescription="OpenDataSoft nous parle de son expérience client avec Jawg Map."
          socialTitle="Témoignage OpenDataSoft"
          title="Témoignage | OpenDataSoft"
        />
        <Page blue>
          <Client
            author={strings["author"]}
            header={header}
            nav={getNavigation("ods", locale)}
            quote={strings["quote"]}
            text={text}
          />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

ClientPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default ClientPage;
